.site-navigation {
  @include media-medium-and-large {
    > li:hover,
    > li:active {
      & > .site-sub-navigation {
        display: flex;
      }
    }

    &.touchable {
      > li:hover,
      > li:active {
        & > .site-sub-navigation {
          display: none;
        }
      }
    }
  }
}

.site-sub-navigation {
  display: none;
  position: absolute;
  z-index: 9997;
  top: 60px;
  left: 0;
  right: 0;

  height: 78px;
  background: #fff;

  $nav-background: #fff;
  $nav-shadow: 0 2px 4px 0 rgba(113, 120, 131, 0.09);

  background: $nav-background;
  box-shadow: $nav-shadow;

  > ul {
    @include clear-list;
    height: inherit;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    // See comments below for why we are reversing the direction.
    // Hint: because CSS has no preceding element selectors
    flex-direction: row-reverse;

    > li {
      display: flex;
      align-items: center;
      height: inherit;
      padding: 0 5px;
    }

    > li > a {
      display: flex;
      align-items: center;
      height: 49px;
      border: 1px solid transparent;
      border-radius: 6px;
      background: transparent;

      @include font-header;
      font-size: 13px;
      padding: 8px 16px;
      text-transform: uppercase;
      text-decoration: none;
      text-align: center;
      color: #5D646F;
    }

    > li.tools > a {
      max-width: 120px;
      padding: 8px 6px;
    }

    // li:last-of-type == the first element displayed, for showing the default category as active
    > li:hover,
    > li:active,
    > li:last-of-type {
      > a {
        border-color: #E5E5E5;
        color: #6B33B3;
        background: #EDEDED;

        // white arrow below gray box
        @include arrow($color: #fff, $size: 16px, $shadow: -3px 3px 4px 0 rgba(113, 120, 131, 0.09), $pseudo: before);
        &:before {
          top: calc(100% + 15px);
        }

        // gray arrow
        @include arrow($color: #EDEDED, $size: 16px, $shadow: -1px 1px 0 #E5E5E5, $pseudo: after);
        &:after {
          z-index: 1;
          top: calc(100% - 1px);
        }
      }

      > .category-navigation {
        display: flex;
        z-index: -5;
      }

      > .navigation-banner {
        display: block;
        z-index: -5;
      }
    }

    // This actually targets the _first_ li shown since we have flex-direction: row-reverse.
    // It's simply a hack so we can target the default selection which comes before the user selected category.
    // This is necessary due to CSS having no preceding element selectors as of Aug 2016.
    > li:hover ~ li:last-of-type {
      > a {
        color: #5D646F;
        background: none;
        border-color: transparent;

        &:before {
          display: none;
        }

        &:after {
          display: none;
        }
      }

      > .category-navigation,
      > .navigation-banner {
        display: none;
      }
    }
  }

  .category-navigation:before,
  .navigation-banner:before {
    // given the hierarchy, fake the navigation above this one's shadow
    position: absolute;
    display: block;
    content: "";
    width: 100%;
    height: 30px;
    top: -30px;
    left: 0;
    right: 0;
    background: $nav-background;
    box-shadow: $nav-shadow;
  }

  .navigation-banner {
    display: none;
    position: absolute;
    top: 78px;
    left: 0;
    right: 0;

    height: 282px;
    background: #f2f2f2;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.24);
    padding: 0;
    margin: 0;

    &:after {
      display: block;
      content: "";
      position: absolute;
      z-index: -1;
      top: 56%;
      right: 0;
      bottom: 0;
      left: 36%;
      clip-path: url(#banner-gradient);
      background: linear-gradient(180deg, rgba(68,73,81,1) 0%, rgba(96,103,114,1) 100%);
    }

    > .wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: center;
      max-width: 870px;
      height: inherit;
      margin: 0 auto;
      padding: 0;

      > .microcopy {
        display: flex;
        flex-flow: column;
        flex-basis: 297px;
        flex-grow: 0;
        flex-shrink: 0;

        height: 100%;
        padding: 36px 0 39px;
        margin: 0;

        strong {
          @include font-header;
          font-size: 26px;
          color: #6B33B3;
          margin: 0 0 10px;
        }

        em {
          @include font-header;
          font-size: 16px;
          font-style: normal;
          letter-spacing: -0.5px;
          color: #444951;
        }

        p {
          @include font-default;
          font-size: 14px;
          line-height: 21px;
          color: #444951;
          margin: 8px 0;
          flex-grow: 1;
          flex-shrink: 1;
        }

        .cta {
          a {
            display: inline-block;
            @include font-header;
            font-size: 20px;
            color: #fff;
            text-align: center;
            min-width: 231px;
            padding: 12px 24px;
            background: #6A33B3;
            border-radius: 24px;
            text-decoration: none;
          }
        }
      }

      > .character {
        position: relative;
        overflow: hidden;
        flex-basis: 218px;
        flex-grow: 0;
        flex-shrink: 0;
        height: 100%;

        img {
          position: absolute;
        }

      }

      > .screenshot {
        border-top: 15px solid #6A33B3;
        border-radius: 15px 15px 0 0;
        background: #fff;
        margin: 32px auto 0 0;

        flex-basis: 324px;
        flex-grow: 0;
        flex-shrink: 0;
        align-self: stretch;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top center;
        }
      }

      > .scene {
        position: relative;
        height: 100%;
        flex-basis: 100%;
        flex-grow: 1;
        flex-shrink: 1;

        img {
          position: absolute;
        }
      }
    }

    > .background {
      position: absolute;
      display: none;
      z-index: -2;
    }

    &.forex.school {
      .scene img {
        width: 484px;
        height: 220px;
        left: 6px;
        top: 38px;
      }

      .background {
        display: block;
        width: 513px;
        height: 182px;
        top: 0;
        left: calc(50% - 16px);
        background-color: #f2f2f2;
        background-image: url('../images/promo-scene-school-wallart.jpg');
        background-blend-mode: multiply;
        background-repeat: no-repeat;
      }
    }

    &.forex.quizzes {
      .scene img {
        width: 559px;
        height: 232px;
        top: 17px;
        left: 17px;
      }
    }

    &.forex.glossary {
      .scene img {
        width: 532px;
        height: 235px;
        top: 16px;
        left: 7px;
      }

      .background {
        display: block;
        width: 125px;
        height: 152px;
        top: 85px;
        left: calc(50% - 78px);
        background: url('../images/promo-scene-glossary-candles.svg') no-repeat;
      }
    }

    &.crypto {
      &:after {
        display: none;
      }

      .scene img {
        height: 282px;
        top: 0;
        z-index: -1;
      }
    }

    &.crypto.school {
      .scene img {
        width: 771px;
        left: -100px;
      }
    }

    &.crypto.quizzes {
      .scene img {
        width: 701px;
        left: -50px;
      }
    }

    &.crypto.glossary {
      .scene img {
        width: 680px;
        left: -60px;
      }
    }

    &.market_hours {
      .character img {
        width: 150px;
        height: 155px;
        left: 24px;
        top: 95px;
      }

      .screenshot {
        padding: 3px 14px 0;
      }
    }

    &.currency_correlation {
      .character {
        flex-basis: 285px;
      }

      .character img {
        width: 215px;
        height: 228px;
        left: 24px;
        top: 31px;
      }

      .screenshot {
        flex-basis: 254px;
        padding: 3px 0 0;
      }
    }

    &.risk_meter {
      .character {
        flex-basis: 260px;
      }

      .character img {
        width: 204px;
        height: 196px;
        left: 40px;
        top: 42px;
      }

      .screenshot {
        flex-basis: 254px;
        padding: 3px 16px 0;

        img {
          object-fit: contain;
        }
      }
    }

    &.pivot_point {
      .character img {
        width: 151px;
        height: 180px;
        left: 10px;
        top: 71px;
      }

      .screenshot {
        flex-basis: 350px;
        padding: 14px 15px 0;
      }
    }

    &.gain_loss {
      .character img {
        width: 203px;
        height: 195px;
        left: -3px;
        top: 55px;
      }

      .screenshot {
        flex-basis: 350px;
        padding: 9px 15px 0 14px;
      }
    }

    &.pip_value {
      .character img {
        width: 228px;
        height: 168px;
        left: 24px;
        top: 82px;
      }

      .screenshot {
        flex-basis: 322px;
        padding: 15px 14px 0;
      }
    }

    &.position_size {
      .character {
        flex-basis: 252px;
      }

      .character img {
        width: 268px;
        height: 204px;
        left: 63px;
        top: 42px;
      }

      .screenshot {
        flex-basis: 255px;
        padding: 15px 15px 0;
      }
    }
  }
}
