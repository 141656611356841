/**
 * #REPLACEMENT MIXINS
 */

@use "sass:math";

@mixin hide-text($direction: left) {
  @if $direction == left {
    $approximate-em-value: math.div(12px, 1em);
    $wider-than-any-screen: -9999em;
    text-indent: $wider-than-any-screen * $approximate-em-value;
    overflow: hidden;
    text-align: left;
  }
  @else {
    // slightly wider than the box prevents issues with inline-block elements
    text-indent: 110%;
    white-space: nowrap;
    overflow: hidden;
  }
}

@mixin squish-text {
  font: 0/0 serif;
  text-shadow: none;
  color: transparent;
}

@mixin replace-text($img, $x: 50%, $y: 50%) {
  @include hide-text;
  background: {
    @if is-url($img) {
      image: $img;
    }
    @else {
      image: image-url($img);
    }
    repeat: no-repeat;
    position: $x $y;
  };
}

@mixin text-overflow-fade($line-height, $transparent-background-color, $background-color) {
  position: relative;
  text-overflow: clip;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: $line-height;
    width: $line-height;

    // The reason for a "transparent background color" is to fix an iOS Mobile Safari
    // bug that won't properly render the keyboard "transparent". It must be the color
    // with an alpha of 0.
    //
    // See: https://ambientimpact.com/web/snippets/safari-bug-with-gradients-that-fade-to-transparent
    background: linear-gradient(to right, $transparent-background-color 0%, $background-color 100%);
  }
}