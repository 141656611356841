$screen-large-max: 9999px;
$screen-large-min: 1200px;

$screen-medium-max: 1199px;
$screen-medium-min: 1024px;

$screen-small-max: 1023px;
$screen-small-min: 768px;

$screen-xsmall-max: 767px;
$screen-xsmall-min: 372px;

$screen-xxsmall-max: 371px;
$screen-xxsmall-min: 0px;

@mixin media-large {
  @media (min-width: $screen-large-min) { @content; }
}

@mixin media-medium-and-large {
  @media (min-width: $screen-medium-min) { @content; }
}

@mixin media-medium {
  @media (max-width: $screen-medium-max) { @content; }
}

@mixin media-medium-and-small {
  @media (max-width: $screen-medium-max) and (min-width: $screen-small-min) { @content; }
}

@mixin media-medium-only {
  @media (max-width: $screen-medium-max) and (min-width: $screen-medium-min) { @content; }
}

@mixin media-small {
  @media (max-width: $screen-small-max) { @content; }
}

@mixin media-small-only {
  @media (max-width: $screen-small-max) and (min-width: $screen-small-min) { @content; }
}

@mixin media-xsmall {
  @media (max-width: $screen-xsmall-max) { @content; }
}

@mixin container-xsmall {
  @container (max-width: $screen-xsmall-max) { @content; }
}

@mixin media-xxsmall {
  @media (max-width: $screen-xxsmall-max) { @content; }
}

@mixin media-notch {
  @supports(padding:max(0px)) { @content; }
}

@mixin media-dpi-2x {
  @media (resolution > 1dppx) and (resolution < 3dppx) { @content; }
}

@mixin media-dpi-3x {
  @media (resolution >= 3dppx) { @content; }
}

@mixin handle-notch {
  @include media-notch {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

@function min($numbers...) {
  @return m#{i}n(#{$numbers});
}

@function max($numbers...) {
  @return m#{a}x(#{$numbers});
}
