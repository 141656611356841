@mixin clear-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin ordered-list {
  @include clear-list;
  counter-reset: li-counter;

  > li:before {
    @include ordered-list-bullet;
  }
}

@mixin ordered-list-bullet($size: 42px, $font-size: 25px) {
  content: counter(li-counter);
  counter-increment: li-counter;

  display: inline-block;
  flex-basis: $size;
  flex-shrink: 0;
  width: $size;
  height: $size;
  line-height: $size;
  background: #29bc1e;
  border-radius: $size * 0.5;
  margin: 0 20px 0 0;

  @include font-header;
  font-size: $font-size;
  text-align: center;
  color: #fff;
}