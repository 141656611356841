$font-header-family: "VAGRundschriftD", Helvetica, Arial, sans-serif;
$font-header-light-family: "VAGRundschriftD-Lig", Helvetica, Arial, sans-serif;

$font-default-family: Arial, Helvetica Neue, Helvetica, sans-serif;
$font-default-semibold-family: Arial, Helvetica Neue, Helvetica, sans-serif;
$font-default-italic-family: Arial, Helvetica Neue, Helvetica, sans-serif;
$font-default-semibold-italic-family: Arial, Helvetica Neue, Helvetica, sans-serif;

$content-text-color: #444951;

@mixin font-header {
  font-family: $font-header-family;
  font-weight: normal;
}

@mixin font-header-light {
  font-family: $font-header-light-family;
  font-weight: normal;
}

@mixin font-default {
  font-family: $font-default-family;
  font-weight: normal;
}

@mixin font-default-semibold {
  font-family: $font-default-semibold-family;
  font-weight: bold;
}

@mixin font-default-italic {
  font-family: $font-default-italic-family;
  font-style: italic;
}

@mixin font-default-semibold-italic {
  font-family: $font-default-semibold-italic-family;
  font-weight: bold;
  font-style: italic;
}
