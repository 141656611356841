.site-quote {
  @include content-width;
  margin: 40px auto;

  blockquote {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    width: 950px;
    margin: 0 auto;
    padding: 0 50px;
    text-align: center;

    > svg {
      display: block;
      position: relative;
      flex-shrink: 0;
      top: -18px;
      fill: var(--primary-cta-color);
    }

    p {
      @include font-header-light;
      font-size: 50px;
      color: var(--primary-cta-color);
      margin: 0 12px;
      flex-shrink: 1;
    }

    cite {
      display: block;
      @include font-header-light;
      font-size: 18px;
      font-style: normal;
      line-height: 32px;
      color: #444951;
      text-align: right;

      &:before {
        content: "- ";
      }
    }

    @include media-small {
      width: 100%;

      p {
        font-size: 40px;
      }
    }

    @include media-xsmall {
      padding: 0 30px;

      .quote:before, .quote:after {
        display: none;
      }

      p span:before, p span:after {
        display: inline;
        font-style: italic;
        padding: 0 6px 0 0;
        content: "\"";
      }

      p {
        font-size: 30px;
      }

      cite {
        text-align: center;
        margin: 14px 0 0;
      }
    }
  }
}

.cardified .site-quote .divider {
  @include tapered-shadow($background: #e3e3e3);
}