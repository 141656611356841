@mixin ad-unit-disclosed {
  &:after {
    display: block;
    content: 'Advertisement';
    @include font-default;
    font-size: 12px;
    color: #b6b8bc;
    text-align: center;
    margin: 4px 0 0;
  }
}

.ad-unit-disclosed {
  @include ad-unit-disclosed;
}