@mixin tapered-shadow($height: 32px, $background: #fff, $direction: 'down') {
  position: relative;
  overflow: hidden;
  height: $height;

  // The shadow
  &:before {
    content: "\0020";
    display: block;
    position: absolute;
    z-index: 800;
    left: 0;
    width: 100%;
    height: 50%;

    @if($direction == 'down') {
      top: -1px; // avoid Chrome glitch of shadow bleeding through 1px at the top
      box-shadow: 0 0 12px rgba(41, 48, 60, 0.25);
    } @else {
      bottom: 0;
      box-shadow: 0 0 12px rgba(41, 48, 60, 0.25);
    }
  }

  // The gradient overlay to make it fade left/right
  &:after {
    content: "\0020";
    display: block;
    position: absolute;
    z-index: 801;
    width: 100%;
    height: calc(50% + 1px);
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, $background 2%, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0) 85%, $background 98%);
  }

  > * {
    position: relative;
    z-index: 802;
  }
}