// default values :
// $position      : top | right | bottom | left
// $size          : any border-accepted length - px, em, etc. (NOT %)
// $color         : any color
// $border-width  : any border-accepted length with units comparable to $size
// $border-color  : any color
// $border-style  : dotted | dashed | solid | double | groove | ridge | inset | outset
// $offset        : 50% | any valid position value - px, em, % (too small or too large will look weird)

@use "sass:math";

@mixin arrow(
  $position     : bottom,
  $size         : 40px,
  $color        : #fff,
  $offset       : 50%,
  $shadow       : 0 2px 4px 0 rgba(113, 120, 131, 0.09),
  $self-position: relative,
  $pseudo       : after
) {
  $arrow-orientation  : null;
  $arrow-position     : left;
  $arrow-top          : null;

  @if ($position == bottom) {
    $arrow-orientation : top;
    $arrow-top : 100%;
  } @else if ($position == top) {
    $arrow-orientation : bottom;
    $arrow-top : 0%;
  } @else if ($position == left) {
    $arrow-orientation : right;
    $arrow-top : -50%;
  } @else if ($position == right) {
    $arrow-orientation : left;
    $arrow-top : $size * 0.75; // fix IE bug where 50% offset does not calculate properly
  }

  @if ($position == right) or ($position == left) {
    $arrow-position   : top;
  }

  @if ($pseudo == after) {
    position: $self-position;
    box-sizing: border-box;
  }

  &:#{$pseudo} {
    display: block;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: $arrow-top;
    left: $offset;
    margin-left: -(math.div($size, 1.5));
    box-sizing: border-box;
    border: ($size * 0.5) solid $color;

    transform-origin: 0 0;
    transform: rotate(-45deg);

    box-shadow: $shadow;
    z-index: -2;
  }
}