$footer-padding-regular: 30px;
$footer-padding-small: 80px;
$footer-padding-xsmall: 60px;

.site-footer {
  display: block;
  width: 100%;
  @include font-default;
  font-size: 15px;
  color: #8d939c;

  a,
  a:link,
  a:visited,
  button {
    text-decoration: none;
    color: #8d939c;
  }

  button.change-consent {
    visibility: hidden;
    text-decoration: none;
    cursor: pointer;
    background: transparent;
    border: none;

    &:hover {
      visibility: visible;
    }
  }

  ul {
    @include clear-list;
  }

  &, .menus {
    background: #33373d;
  }

  .legal {
    background: #2b2e32;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 $footer-padding-regular;

    @include media-small {
      display: block;
      padding: 0;
    }
  }

  .footer-menus {
    display: flex;
    flex-wrap: nowrap;
    flex-basis: 50%;
    justify-content: space-between;
    border-right: 1px solid #3b4048;
    padding: 48px 0 8px;

    @include media-small {
      border-right: none;
      border-bottom: 1px solid #3b4048;
      padding-left: $footer-padding-small;
      padding-right: $footer-padding-small;
      flex-wrap: wrap;
    }

    @include media-xsmall {
      padding-left: $footer-padding-xsmall;
      padding-right: $footer-padding-xsmall;

      .menu-learn { order: 1; }
      .menu-tools { order: 3; }
      .menu-company { order: 2; }

      .menu:nth-child(odd) {
        margin-right: 0;
      }
    }

    .title {
      @include font-header;
      text-transform: uppercase;
      font-size: 14px;
      color: #fff;
      margin: 0 0 20px;
    }

    .menu {
      margin: 0 50px 40px 0;
    }

    .menu li {
      margin: 0 0 16px 0;
    }

    .menu a {
      font-size: 14px;
    }

    .menu-company li {
      white-space: nowrap;
    }
  }

  .footer-info {
    flex-basis: 50%;
    padding: 48px 0 48px 50px;

    @include media-small {
      padding-left: $footer-padding-small;
      padding-right: $footer-padding-small;
    }

    @include media-xsmall {
      padding-left: 30px;
      padding-right: 30px;
    }

    .title-line {
      display: flex;
    }

    .title {
      > svg {
        display: block;
        width: 39px;
        height: 38px;
        margin: 0 14px 0 0;

        --logo-primary: #fff;
        --logo-shade: #e0e0e0;
      }

      @include font-header;
      display: flex;
      flex-flow: row;
      line-height: 38px;
      font-size: 23px;
      text-transform: lowercase;
      text-align: left;
      color: #fff;
      margin: 0 40px 0 0;
    }

    .social {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      li {
        margin-right: 25px;

        &:last-child {
          margin-right: 0;
        }
      }

      li a {
        @include hide-text;
        display: inline-block;
        background-position: 50% 50%;
      }

      .facebook a { @include svg-icon('icon-social-facebook-footer', 9px, 17px); }
      .instagram a { @include svg-icon('icon-social-instagram-footer', 20px, 20px); }
      .twitter a { @include svg-icon('icon-social-twitter-footer', 22px, 18px); }
    }

    p.heading {
      @include font-header;
      font-size: 15px;
    }

    .newsletter {
      margin-top: 60px;
    }
  }

  .legal {
    font-size: 12px;
    padding: 24px 0;

    .wrapper {
      @include media-small {
        padding-left: $footer-padding-small;
        padding-right: $footer-padding-small;
      }

      @include media-xsmall {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
      }
    }

    p {
      margin: 0 30px 0 0;

      @include media-small {
        text-align: center;
        margin: 0 0 12px;
      }
    }

    ul {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      @include media-small {
        margin: 0 auto;
        justify-content: center;
      }

      @include media-xsmall {
        padding: 0 10px;
        flex-wrap: wrap;
        gap: 10px;
      }
    }

    li {
      margin: 0 20px 0 0;

      &:last-child {
        margin-right: 0;
      }

      @include media-xsmall {
        margin: 0;
        font-size: 10px;
        text-align: center;
      }
    }
  }
}
