$large-content-width: 1170px;
$medium-content-width: 970px;
$small-content-width: 100vw;

@mixin content-width {
  width: $large-content-width;

  @include media-medium {
    width: $medium-content-width;
  }

  @include media-small {
    width: $small-content-width;
  }
}

@mixin max-content-width {
  max-width: $large-content-width;

  @include media-medium {
    max-width: $medium-content-width;
  }

  @include media-small {
    max-width: $small-content-width;
  }
}